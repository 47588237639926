import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useBreakpointValue } from '@chakra-ui/react';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { useCurrentUser } from '@frontend/domain/contexts/User/UserContext';
import { useReportList } from '@frontend/domain/hooks/Report/ReportListHook';
import { useEffect } from 'react';
import { PageHeader } from '../../pages/Selection/Header/PageHeader';
import { ExpandableSidebar } from '../navigation/ExpandableSidebar';
export const PageLayout = ({ title, children, }) => {
    var _a;
    const { selectedOrganizationIdentity, organizations, isLoading, onSelectOrganization, } = useOrganizationSummaries();
    const { user } = useCurrentUser();
    const { fetch, reports } = useReportList();
    useEffect(() => {
        if (selectedOrganizationIdentity) {
            fetch(selectedOrganizationIdentity);
        }
    }, [selectedOrganizationIdentity]);
    const dropdownLabel = (_a = useBreakpointValue({
        base: 'Org',
        lg: 'Organization',
    })) !== null && _a !== void 0 ? _a : 'Organization';
    return (_jsx(ExpandableSidebar, Object.assign({ orgIdentity: selectedOrganizationIdentity, reports: reports, currentUser: user }, { children: _jsxs(_Fragment, { children: [_jsx(PageHeader, { title: title !== null && title !== void 0 ? title : '', selectedOrganizationIdentity: selectedOrganizationIdentity, organizations: organizations, isLoading: isLoading, onSelectOrganization: onSelectOrganization, dropdownLabel: dropdownLabel }), children] }) })));
};
