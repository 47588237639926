var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFacilityContext } from '@/contexts/Facilities/FacilityPageContext';
import { CheckIcon } from '@chakra-ui/icons';
import { Box, Center, HStack, IconButton, Input, ListItem, Text, useDisclosure, useToast, } from '@chakra-ui/react';
import { ActionMenu } from '@frontend/design-system/components/ActionMenu/ActionMenu';
import { useEffect, useRef, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaChartBar, FaTrash } from 'react-icons/fa';
import { slgqColors } from '@frontend/design-system/theme/theme';
import { PageModes } from '../../../../objects/FCAInterfaces';
import { updatePlan } from '../../../../services/api/planAPI';
import { DeletePlanModal } from '../PlansSidebar/DeletePlanModal';
import { UploadPlanModal } from '../PlansSidebar/UploadPlanModal';
import { SidebarItem } from './SidebarItem';
import useLongPress from './useLongPress';
export const PlanSidebarItem = ({ facilityIdentity, plans, plan, canEdit, updateCurrentPlans, handlePlanCreate, handlePlanDelete, scrolling, setScrolling, scrollingAuto, setScrollingAuto, }) => {
    const { selectPlan, selectedPlan, setPageMode, mapMode, setMapMode } = useFacilityContext();
    const [isOpenActions, setIsOpenActions] = useState(false);
    const [planRename, setPlanRename] = useState(false);
    const [planName, setPlanName] = useState(plan.name);
    const toast = useToast();
    const planRef = useRef(null);
    const { isOpen: isOpenUpload, onOpen: onOpenUpload, onClose: onCloseUpload, } = useDisclosure();
    const { isOpen: isOpenPlanDelete, onOpen: onOpenPlanDelete, onClose: onClosePlanDelete, } = useDisclosure();
    const actions = [
        {
            name: 'Edit Name',
            icon: _jsx(BiEdit, { fontSize: '18px' }),
            display: 'block',
            handleClick: () => handlePlanRename(),
            handleTouch: () => {
                if (scrolling) {
                    setScrolling(false);
                }
                else {
                    handlePlanRename();
                    setIsOpenActions(false);
                }
            },
        },
        {
            name: 'Edit Plan Image',
            icon: _jsx(BiEdit, { fontSize: '18px' }),
            display: navigator.onLine ? 'block' : 'none',
            handleClick: () => {
                onOpenUpload();
            },
            handleTouch: () => {
                if (scrolling) {
                    setScrolling(false);
                }
                else {
                    onOpenUpload();
                    setIsOpenActions(false);
                }
            },
        },
        {
            name: 'Delete Plan',
            icon: _jsx(FaTrash, { fontSize: '18px' }),
            display: 'block',
            handleClick: () => onOpenPlanDelete(),
            handleTouch: () => {
                if (scrolling) {
                    setScrolling(false);
                }
                else {
                    onOpenPlanDelete();
                    setIsOpenActions(false);
                }
            },
        },
    ];
    // Scrolls plan to the Current Plan.
    useEffect(() => {
        var _a;
        if (scrollingAuto) {
            setScrollingAuto(false);
        }
        else {
            if (planRef && selectedPlan) {
                if (plan.id === selectedPlan.id) {
                    (_a = planRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                }
            }
        }
        setPlanRename(false);
    }, [selectedPlan]);
    const handlePlanRename = () => {
        setPlanRename(!planRename);
    };
    const resetPageModeView = () => {
        setPageMode(PageModes.VIEW);
    };
    const handleTouchMove = () => {
        if (!scrolling) {
            setScrolling(true);
        }
        if (!scrollingAuto) {
            setScrollingAuto(true);
        }
    };
    const handleMouseDown = () => {
        resetPageModeView();
        selectPlan(plan);
    };
    const handleTouchEnd = () => {
        if (scrolling) {
            setScrolling(false);
        }
        else {
            resetPageModeView();
            selectPlan(plan);
        }
    };
    const handleContextMenu = () => {
        if (scrolling) {
            setScrolling(false);
        }
        else {
            resetPageModeView();
            setIsOpenActions(true);
        }
    };
    const onLongPress = () => {
        setIsOpenActions(true);
    };
    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };
    const longPressEvent = useLongPress(onLongPress, defaultOptions);
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (selectedPlan) {
            try {
                // TODO: updated places where plan needs to be used instead of selectedPlan(card 2415)
                const updatedPlan = yield updatePlan(selectedPlan.id, {
                    name: planName,
                });
                updateCurrentPlans(plan, updatedPlan);
                toast({
                    title: 'Plan updated',
                    status: 'success',
                    isClosable: true,
                });
            }
            catch (_a) {
                toast({
                    title: 'Plan update failed',
                    description: 'Please check that all fields are entered correctly.',
                    status: 'error',
                    isClosable: true,
                });
            }
            finally {
                setPlanRename(false);
            }
        }
        else {
            setPlanRename(false);
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(SidebarItem, { children: _jsx(ListItem, Object.assign({ ref: planRef, position: 'relative', onContextMenu: (e) => e.preventDefault() }, { children: _jsxs(HStack, Object.assign({ spacing: '15px', onTouchEnd: handleTouchEnd, onMouseDown: handleMouseDown }, { children: [_jsx(Center, Object.assign({ w: '25px', color: slgqColors.secOrange }, { children: (selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.id) === plan.id && _jsx(CheckIcon, {}) })), planRename ? (_jsx(HStack, { children: _jsxs("form", Object.assign({ onSubmit: (e) => handleSubmit(e) }, { children: [_jsx(Input, { h: '24px', w: '70%', pl: '5px', pr: '5px', defaultValue: plan.name, onChange: (e) => {
                                                setPlanName(e.target.value);
                                            }, "data-testid": 'rename-plan-input' }), _jsx(IconButton, { type: 'submit', h: '24px', "aria-label": 'rename plan', icon: _jsx(CheckIcon, {}) })] })) })) : (_jsxs(HStack, Object.assign({}, longPressEvent, { onContextMenu: handleContextMenu, w: '100%', justifyContent: 'space-between', "data-testid": `${plan.identity}: ${plan.name}`, userSelect: 'none' }, { children: [_jsx(Text, { children: plan.name }), _jsx(Box, Object.assign({ w: 'fit-content' }, { children: _jsx(FaChartBar, { color: mapMode ? '#F6862A' : '', fontSize: '20px', onClick: () => {
                                                if (mapMode) {
                                                    setPageMode(PageModes.VIEW);
                                                }
                                                setMapMode((mapMode) => !mapMode);
                                            }, onTouchEnd: () => {
                                                if (mapMode) {
                                                    setPageMode(PageModes.VIEW);
                                                }
                                                setMapMode((mapMode) => !mapMode);
                                            } }) }))] }))), canEdit && (_jsx(ActionMenu, { actions: actions, isOpenActions: isOpenActions, onCloseActions: () => setIsOpenActions(false), handleTouchMove: handleTouchMove, uniqueStyles: {
                                    top: 5,
                                    left: 0,
                                    maxHeight: '90px',
                                    minWidth: '160px',
                                    overflow: 'auto',
                                    border: '1px solid white',
                                } }))] })) })) }), _jsx(DeletePlanModal, { isOpen: isOpenPlanDelete, onClose: onClosePlanDelete, plans: plans, handlePlanDelete: handlePlanDelete }), _jsx(UploadPlanModal, { isOpen: isOpenUpload, onClose: onCloseUpload, editMode: true, updateCurrentPlans: updateCurrentPlans, handlePlanCreate: handlePlanCreate, facilityIdentity: facilityIdentity })] }));
};
